import axios from 'axios';
import { useState } from 'react';

import useUserInfo from '@/hooks/queries/user/useUserInfo';
import LoginUser from '@/components/NotFound/LoginUser';
import UnAuthorizedUser from '@/components/NotFound/UnAuthorizedUser';

const NotFoundPage = () => {
  const [isUserLogin, setIsUserLogin] = useState(false);

  useUserInfo({
    enabled: true,
    refetchOnWindowFocus: false,
    onSuccess: () => {
      setIsUserLogin(true);
    },
    onError: (error) => {
      if (axios.isAxiosError(error) && error.response?.data) {
        const { status } = error.response;
        if (status === 403) {
          setIsUserLogin(false);
        }
      }
    },
  });
  return isUserLogin ? <LoginUser /> : <UnAuthorizedUser />;
};

export default NotFoundPage;
