import { Stack, Icon, Text, Button } from '@pairy/pds-react';
import { useRouter } from 'next/router';

import Header from '../../../components/Layouts/User/Header';

const LoginUser = () => {
  const router = useRouter();

  return (
    <div>
      <Header />
      <Stack
        gap={32}
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ width: '100%', height: '100vh' }}
      >
        <Stack gap={16} alignItems="center" direction="column">
          <Icon icon="ph:cactus-light" size={96} color="zinc_500" />
          <Text variant="2xl-semibold" color="zinc_500" textAlign="center">
            죄송합니다.
            <br />
            해당 페이지를 찾을 수 없습니다.
          </Text>
          <Text variant="lg-medium" color="zinc_400" textAlign="center">
            찾으려는 페이지의 주소가 잘못 입력되었거나,
            <br /> 주소의 변경 혹은 삭제로 인해 사용하실 수 없습니다. <br />
            입력하신 페이지의 주소가 정확한지 확인해 주세요.
          </Text>
        </Stack>
        <Stack gap={16} alignItems="center" direction="column">
          <Button
            variant="black_fill"
            width="256px"
            radius={9999}
            paddingX={16}
            paddingY={16}
            onClick={() => {
              router.push('/user/pdf');
            }}
          >
            메인으로 돌아가기
          </Button>
        </Stack>
      </Stack>
    </div>
  );
};

export default LoginUser;
